

// List Reset
%list-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

// Vertical Centering
%vertical-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

// Columns
@mixin columns($columns) {
	width: percentage(1/$columns);
}

// Float with margin variable
@mixin float($direction, $margin: 0) {
	float: $direction;
	margin-#{opposite-direction($direction)}: $margin;
}
