@mixin transform($property: none) {
//  none | <transform-function>
  @include prefixer(transform, $property, webkit moz ms o spec);
}

@mixin transform-origin($axes: 50%) {
// x-axis - left | center | right  | length | %
// y-axis - top  | center | bottom | length | %
// z-axis -                          length
  @include prefixer(transform-origin, $axes, webkit moz ms o spec);
}

@mixin transform-style ($style: flat) {
  @include prefixer(transform-style, $style, webkit moz ms o spec);
}
