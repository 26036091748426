@function _radial-positions-parser($gradient-pos) {
  $shape-size: nth($gradient-pos, 1);
  $pos:        nth($gradient-pos, 2);
  $shape-size-spec: _shape-size-stripper($shape-size);

  $pre-spec: unquote(if($pos, "#{$pos}, ", null))
             unquote(if($shape-size, "#{$shape-size},", null));
  $pos-spec: if($pos, "at #{$pos}", null);

  $spec: "#{$shape-size-spec} #{$pos-spec}";

  // Add comma
  @if ($spec != '  ') {
    $spec: "#{$spec},"
  }

  @return $pre-spec $spec;
}
