//************************************************************************//
// Example: @include prefixer(border-radius, $radii, webkit ms spec);
//************************************************************************//
// Variables located in /settings/_prefixer.scss

@mixin prefixer ($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      @if $prefix-for-webkit {
        -webkit-#{$property}: $value;
      }
    }
    @else if $prefix == moz {
      @if $prefix-for-mozilla {
        -moz-#{$property}: $value;
      }
    }
    @else if $prefix == ms {
      @if $prefix-for-microsoft {
        -ms-#{$property}: $value;
      }
    }
    @else if $prefix == o {
      @if $prefix-for-opera {
        -o-#{$property}: $value;
      }
    }
    @else if $prefix == spec {
      @if $prefix-for-spec {
        #{$property}: $value;
      }
    }
    @else  {
      @warn "Unrecognized prefix: #{$prefix}";
    }
  }
}

@mixin disable-prefix-for-all() {
  $prefix-for-webkit:    false !global;
  $prefix-for-mozilla:   false !global;
  $prefix-for-microsoft: false !global;
  $prefix-for-opera:     false !global;
  $prefix-for-spec:      false !global;
}
